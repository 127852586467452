<template>
  <div>
    <k-crud-page>
      <template #condition>
        <div class="d-flex flex-wrap my-4">
          <div class="data_div">
            <k-Start-Time-select
              v-model="download_time_window"
              :placeholder="xiazai"
              label="下载窗口配置"
            />
          </div>
          <div class="data_div">
            <k-Start-Time-select
              v-model="upgrade_time_window"
              :placeholder="xiazai"
              label="升级窗口配置"
            />
            <!-- <k-Ent-Time-select
              v-model="cruddataMX.conditions.upgrade_time_window"
              :placeholder="shenji"
              label="升级窗口配置"
            /> -->
          </div>
        </div>
        <k-primary-button
          v-if="hasPermission(['software.data.add'])"
          class="my-1 mr-4 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
          text="保存"
          :loading="saveLoading"
          icon="mdi-magnify"
          @click="onclick"
        />
        <!-- </div> -->
      </template>
      <template #resultlist>
        <software :value="searchValve" :dosearchflg="doSearchFlg" />
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import software from './softwarelist/index.vue';
export default {
  components: {
    software,
  },
  data() {
    return {
      saveLoading: false,
      xiazai: '',
      shenji: '19:00-20:00',
      download_time_window: [],
      upgrade_time_window: [],

      rules: {
        required: (value) => !!value || 'Required.',
      },
      searchValve: '',
      doSearchFlg: true,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getDate();
  },
  methods: {
    getDate() {
      this.$api.software.software.gettimesettingn().then((res) => {
        if (res.code === 200) {
          if (res.data[0] !== {}) {
            this.download_time_window =
              res.data[0].download_time_window.split('-');
            this.upgrade_time_window =
              res.data[0].upgrade_time_window.split('-');
          }
        }
      });
    },
    onclick() {
      this.saveLoading = true;
      const parmas = {
        download_time_window: `${this.download_time_window[0]}-${this.download_time_window[1]}`,
        upgrade_time_window: `${this.upgrade_time_window[0]}-${this.upgrade_time_window[1]}`,
      };
      this.$api.software.software
        .getAddTime(parmas)
        .then((res) => {
          if (res.code === 200) {
            this.$alert.success(res.message);
            this.saveLoading = false;
            this.getDate();
          }
        })
        .catch(() => {
          this.saveLoading = false;
        });

      this.doSearchFlg = !this.doSearchFlg;
    },
    changeselect(text) {
      this.searchValve = text;
      console.log(text, 'text');
    },
  },
};
</script>
<style lang="scss" scoped>
.data_div {
  width: 100%;
}
.d-flex .align-center {
  width: 25% !important;
}
.mr-2 .text-right .input-label {
  width: 30% !important;
}
</style>
